import { Contexts } from '@/auth/auth-context/enums/Contexts';
import { usePermission } from '@/auth/usePermission';
import { Skeleton, TableCell } from '@mui/material';

interface TableCellActionSkeletonProps {
  action: 'delete' | 'more';
  ctx: Contexts;
}

export default function TableCellActionSkeleton({
  action,
  ctx,
}: TableCellActionSkeletonProps) {
  const { hasDeletePermission, hasUpdatePermission } = usePermission(ctx);

  const hasReadPermission = !hasDeletePermission && !hasUpdatePermission;

  if (
    (action === 'delete' && !hasDeletePermission) ||
    (action === 'more' && hasReadPermission)
  ) {
    return null;
  }

  return (
    <TableCell width={56}>
      <Skeleton
        variant="rectangular"
        width={24}
        height={24}
        sx={{ borderRadius: 1 }}
      />
    </TableCell>
  );
}
