import {
  Box,
  Checkbox,
  SxProps,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import { Theme } from '@mui/material/styles';

import Iconify from '../iconify/Iconify';

const visuallyHidden = {
  border: 0,
  margin: -1,
  padding: 0,
  width: '1px',
  height: '1px',
  overflow: 'hidden',
  position: 'absolute',
  whiteSpace: 'nowrap',
  clip: 'rect(0 0 0 0)',
} as const;

type Props = {
  order?: 'asc' | 'desc';
  orderBy?: string;
  headLabel: any[];
  rowCount?: number;
  numSelected?: number;
  onSort?: (id: string) => void;
  onSelectAllRows?: (checked: boolean) => void;
  sx?: SxProps<Theme>;
  isSmallFontLabel?: boolean;
  captalizeText?: boolean;
};

export default function TableHeadCustom({
  order,
  orderBy,
  rowCount = 0,
  headLabel = [],
  numSelected = 0,
  onSort,
  onSelectAllRows,
  sx,
  isSmallFontLabel,
  captalizeText,
}: Props) {
  return (
    <>
      <TableHead sx={sx}>
        <TableRow>
          {onSelectAllRows && (
            <TableCell padding="checkbox">
              <Checkbox
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  onSelectAllRows(event.target.checked)
                }
              />
            </TableCell>
          )}

          {headLabel.map(headCell => {
            const isSortActive = headCell.sortActive ?? true;
            const isActive = orderBy === headCell.id;
            return (
              <TableCell
                key={headCell.id}
                align={headCell.align || 'left'}
                {...(order &&
                  orderBy && {
                    sortDirection: orderBy === headCell.id ? order : false,
                  })}
                sx={{
                  width: headCell.width,
                  minWidth: headCell.minWidth,
                  maxWidth: headCell.maxWidth,
                }}
              >
                {isSortActive && onSort ? (
                  <TableSortLabel
                    hideSortIcon
                    active={isActive}
                    direction={orderBy === headCell.id ? order : 'asc'}
                    onClick={() => onSort(headCell.id)}
                    sx={{
                      textTransform: captalizeText ? 'captalize' : 'none',
                      fontSize: isSmallFontLabel ? '12px' : null,
                    }}
                  >
                    {headCell.label}
                    {!isActive && (
                      <Iconify
                        icon="tabler:arrows-sort"
                        style={{ marginLeft: 4 }}
                      />
                    )}
                    {orderBy === headCell.id ? (
                      <Box sx={{ ...visuallyHidden }}>
                        {order === 'desc'
                          ? 'sorted descending'
                          : 'sorted ascending'}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                ) : (
                  <Typography
                    sx={{
                      textTransform: captalizeText ? 'captalize' : 'none',
                      fontSize: isSmallFontLabel ? '12px' : null,
                    }}
                  >
                    {headCell.label}
                  </Typography>
                )}
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
    </>
  );
}
